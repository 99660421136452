div.sbm-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 1fr 6rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    padding: 5px 5px 5px 5px;
  }
  .item-unit-number {
    padding: 5px 5px 5px 5px;
  }
}

// flexでは配置しきれないのでgridを使う

div.sbm-item-div:not(:last-child) {
  padding: 0.5rem 0 0.5rem 0;
}

div.sbm-item-div:last-child {
  padding: 0.5rem 0 0 0;
}

div.sbm-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 1fr 6rem;
  /* no, img, maker, name, model_number, product_code, spec, unit_number */
  font-size: 14px;
  .item-no {
    grid-column-start: 1;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column-start: 2;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column-start: 3;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
    //min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    //max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-unit-number {
    grid-column-start: 8;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .operation-area {
    grid-column: 8 / 9;
    grid-row: 2 / 3;
  }

  .sbm-expired-at-area {

    border-top: 1px dashed #dee2e6;

    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 3;

    display: grid;
    grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 1fr 6rem;


    .sbm-expired-at-title {
      grid-column: 1 / 3;
    }

    .sbm-expired-at-details {
      grid-column: 3 / -1;

      // 部署自体もグリッドにする
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .detail {
        display: grid;
        grid-template-columns: 4rem 10rem 1fr 4rem;
        padding: 0.4rem;

        .sbm-expired-at {
          grid-column: 2 / 3;
        }
        .sbm-amount {
          grid-column: 3 / 4;
        }
        .del-expired-at-btn {
          grid-column: 4 / 5;
        }

      }

    }
  }
  .sbm-add-expired-at-btn-field {
    grid-row: 4 / 5;
    grid-column: 1 / -1;
  }
}