// ヘッダの設定
.delivery-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr) 5.5rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-tax-rate {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

// 商品の情報と、部署ごとの情報を含んだdiv
.delivery-item-wrapper {
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
}

// flexでは配置しきれないのでgridを使う
div.delivery-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr) 5.5rem;

  /* no, img, maker, name, model_number, product_code */
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-tax-rate {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
  }
  /* ここまでが1行目(Noと画像だけは2行目にもかかる) */

  .grid-label {
    padding: 5px 5px 5px 5px;
    color: #666666;
  }

  .grid-value {
    padding: 5px 5px 5px 5px;
  }

  /* 2行目 */

  .delivery-details-div {
    grid-column: 1 / 10;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 5px;

    .item-unit-number {
      grid-column: 1 / 2;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .item-assigned-amount {
      grid-column: 2 / 3;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .item-unit-price {
      grid-column: 3 / 4;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
      
    }

    .item-total-price {
      grid-column: 4 / 5;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
    .item-amount {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

    }
    .item-undelivered-amount {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .item-deliveried-total-price {
      grid-column: 4 / 5;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .item-delivery-blank-div{
      grid-column: 3 / 4;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #fff;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
  }
}

div.delivery-total-wrapper {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(4, 1fr) 0.5fr 0.5fr 1fr 3rem;

  .delivery-total-div {
    grid-column: 3 / -1;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .delivery-subtotal-price {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-top: 10px;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
    .delivery-total-tax-price {
      grid-column: 4 / 5;
      grid-row: 2 / 3;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
    .delivery-total-price {
      grid-column: 4 / 5;
      grid-row: 3 / 4;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
  }
}
