.stock-list-area {
  width: 100%;

  display: grid;
  grid-template-columns: 6rem 1fr 1fr;

  .stock-list-title {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .stock-list-details {
    grid-column: 2 / -1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .detail {
      display: grid;
      grid-template-columns: 4rem 10rem 1fr 4rem;
      align-items: center;
      padding: 0.4rem;
  
      .stock-expired-at {
        grid-column: 1 / 2;
      }
      .stock-expired-at {
        grid-column: 2 / 3;
      }
  
    }
  }
}
